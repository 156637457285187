<template>
  <div class="container">
    <v-row>
      <v-col cols="12" md="3">
        <v-select
          class="pt-5 pa-2"
          label="Option"
          dense
          prepend-inner-icon="mdi-graph"
          background-color="grey lighten-4"
          rounded
          v-model="searchOption"
          :items="Options.map((item) => item.name)"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          class="pt-5 pa-2"
          label="Niveau"
          prepend-inner-icon="mdi-magnify"
          dense
          background-color="grey lighten-4"
          rounded
          v-model="searchLevel"
          :items="[100, 200, 300, 400, 500]"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="searchOption && searchLevel">
      <div class="d-md-flex justify-space-between mb-5 mt-12 py-5 container">
        <h3 class="text-h5">Emploi du temps</h3>
        <div class="mt-md-0 mt-4">
          <v-btn
            color="#144881"
            dark
            class="mb-2 mr-8"
            @click="printTimeTable"
            outlined
            pill
            :loading="loading"
            rounded
          >
            <v-icon left> mdi-timetable </v-icon>
            Imprimer l'emploi du temps de la semaine du {{ Query.startDate }}
          </v-btn>
        </div>
      </div>
      <calendar-x
        :list="TimesTables"
        :showSelectType="false"
        type="week"
        @edit="updateTimesTable"
        @event="handledEvent"
      />
    </div>
    <div v-else>
      <p class="text-center">Veuillez choisir une mention et un dégré</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { updateTimesTable, addTimesTable, printTimeTable } from "@/api/user";
import { functions, rules } from "@/utils/constant";
import { defaultMethods, messages } from "@/utils/data";

import CalendarX from "@/components/universal/CalendarX.vue";
//   import DeleteDialog from "@/components/universal/DeleteDialog.vue";
//   import EditorX from "@/components/universal/EditorX.vue";

export default {
  name: "TimeTable",
  components: {
    //   EditorX,
    //   DeleteDialog,
    CalendarX,
  },
  data() {
    return {
      callBack: "",
      searchData: "",
      searchLevel: "",
      dialog: false,
      loading: false,
      timesTableDialog: false,

      editedIndex: -1,
      Model: {},
      defaultItem: {},
      editorDialog: false,
      deleteDialog: false,
      functions: functions,
      rules: rules,
      searchOption: "",
      Query: {
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    ...mapGetters(["Options", "TimesTables"]),
  },
  methods: {
    ...mapActions(["getOptions", "getTimesTables"]),

    async init() {
      this.loading = true;
      try {
        if (this.searchOption) {
          let option = "";
          option = functions.getItemId(this.Options, this.searchOption);

          await this.getTimesTables({
            option: option,
            level: this.searchLevel,
            startDate: this.Query.startDate,
            endDate: this.Query.endDate,
          });
        }

        await this.getOptions({ department: "" });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
    },
    handledEvent(event) {
      this.Query.startDate = event.start.date;
      this.Query.endDate = event.end.date;
      this.init();
    },
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },
    addTimesTable() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addTimesTable;
      this.timesTableDialog = true;
    },
    updateTimesTable(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.timesTableDialog = true;
      this.callBack = updateTimesTable;
    },
    async printTimeTable() {
      this.loading = true;
      try {
        let option = "";
        option = functions.getItemId(this.Options, this.searchOption);

        let paths;
        paths = await printTimeTable({
          schema: {
            option: option,
            level: this.searchLevel,
            startDate: this.Query.startDate,
            endDate: this.Query.endDate,
          },
        });

        if (paths && !Array.isArray(paths)) {
          paths = [paths];
        }
        for (let url of paths) {
          await functions.downloadFromLink(url);
          await functions.sleep(2000);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
      this.init();
    },
  },
  watch: {
    searchOption() {
      this.init();
    },
    searchLevel() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
</style>